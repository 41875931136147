import * as Sentry from '@sentry/browser';

import { ContextLines, Dedupe, HttpClient } from '@sentry/integrations';

declare global {
  interface Window {
    embarkSentryData: {
      dsn: string;
      sampleRate: number;
      themeVersion: string;
      wordpressVersion: string;
      environment: string;
    };
  }
}

const dsnUrl = window?.embarkSentryData?.dsn || '';

function initSentry(): void {
  const sentryConfig = {
    dsn: dsnUrl,
    release: window?.embarkSentryData?.themeVersion || 'unknown',
    environment: window?.embarkSentryData?.environment || 'unknown',
    integrations: [new ContextLines(), new Dedupe(), new HttpClient()],
    sendDefaultPii: true, // This option is required for capturing headers and cookies from the request that triggered the error.
    sampleRate: window?.embarkSentryData?.sampleRate || 1,

    ignoreErrors: [
      "Can't find variable: _AutofillCallbackHandler", // this is an ios webview error from FB/Instagram that is not actionable
    ],
    allowUrls: [
      // allow only 1st party domains for now. We should revisit this and allow 3rd party domains once we have solved issues in our own scripts.
      'https://embarkvet.com/',
      'https://front.embarkvet.com/',
      'https://shop.embarkvet.com/',
      // multidevs follow this pattern
      /https?:\/\/.*?-embarkvet-wp\.pantheonsite\.io/i,
    ],
  };

  Sentry.init(sentryConfig);

  Sentry.setTag('wordpress', window?.embarkSentryData?.wordpressVersion || 'unknown');
}

if (dsnUrl !== '') {
  initSentry();
}
